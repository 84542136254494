/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

html {
    font-size: 10px;
}

body {
    font-size: 14px;
    margin: 0;
}

#dsd-app {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: radial-gradient(#1d1d1d, #000);
    color: #FFF;
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    line-height: 1.5;

    a {
        cursor: pointer;
    }

    .margin-y-10 {
        margin: 10px 0;
    }

    .margin-y-15 {
        margin: 15px 0;
    }

    .padding-top-20 {
        padding-top: 20px;
    }

    .dsd {
        display: flex;
        align-items: stretch;
        padding: 0;
        width: 100%;

        .carousel {
            display: flex;
            width: inherit;

            .carousel-inner {
                display: flex;
                align-items: stretch;
                align-self: stretch;

                .carousel-item {
                    backface-visibility: hidden;
                    float: left;
                    margin-right: -100%;
                    position: relative;
                    width: 100%;
                    display: none;
                    &.active, &.carousel-item-next, &.carousel-item-prev {
                        display: flex;
                    }

                    .container-fluid {
                        background-position: 0 0;
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        width: 100%;

                        .left-column {
                            padding: 60% 20% 0% 20%;
                            height: 92vh;

                            .title-info {
                                position: absolute;
                                width: 60%;
                                bottom: 0;
                                font-size: 15px;
                            }

                            .title-info h1 {
                                font-size: 25px !important;
                                line-height: 1.2;
                                margin-top: 0;
                                margin-bottom: 5px;
                            }

                            .title-info small {
                                font-size: 80%;
                            }
                        }

                        .right-column {
                            padding: 0% 20% 0% 0%;
                            height: 92vh;

                            .nav {
                                display: flex;
                                flex-wrap: wrap;
                                margin-bottom: 10px;
                                justify-content: center;
                                padding: 5px;
                                font-size: 15px;
                                line-height: 22.5px;
                                text-align: left;
                                height: 60px;


                                .nav-item {
                                    display: list-item;
                                    &:not(:last-child) {
                                        margin-right: 5px;
                                    }

                                    .nav-link {
                                        font-size: 24px;
                                        color: #9f9b9c;
                                        padding: 5px 10px;

                                        &:hover {
                                            background-color: transparent;
                                        }
                                    }
                                }

                                .nav-item.active {

                                    .nav-link {
                                        color: #FFF;

                                        &:hover {
                                            background-color: transparent;
                                        }

                                        &:after {
                                            content: "";
                                            display: block;
                                            transition: all .1s linear;
                                            border-bottom: 3px solid #FFF;
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            .title-content {
                                height: 84vh;

                                .img-responsive {
                                    display: block;
                                    max-width: 100%;
                                    height: auto;
                                    width: 100%;
                                }

                                .title-synopsis {
                                    text-align: left;
                                    margin-top: 1rem;
                                    padding: 5%;
                                    max-height: 35vh;
                                    display: flex;
                                    flex-wrap: wrap;
                                    align-content: flex-start;
                                    overflow-y: scroll;
                                    position: relative;
                                    top: 16%;
                                }

                                /* STUDIO-11902 */
                                .title-synopsis {
                                    /* most used smaller than average screen sizes */
                                    @media (max-height: 900px) {
                                        @media (max-width: 1660px) {
                                            top: 10%;
                                        }
                                        @media (max-width: 1536px) {
                                            top: 8%;
                                        }
                                        @media (max-width: 1440px) {
                                            top: 7%;
                                        }
                                    }
                                    @media (max-height: 768px) {
                                        @media (max-width: 1366px) {
                                            top: 4%;
                                            max-height: 32vh;
                                        }
                                        @media (max-width: 1280px) {
                                            top: 1%;
                                            max-height: 32vh;
                                        }
                                    }
                                    /* rare and very small screen sizes */
                                    @media (max-height: 690px) {
                                        top: 1%;
                                        max-height: 28vh;
                                    }
                                    @media (max-height: 640px) {
                                        top: 1%;
                                        max-height: 24vh;
                                    }
                                    @media (max-height: 600px) {
                                        top: 1%;
                                        max-height: 20vh;
                                    }
                                    /* rare and very big screen sizes */
                                    @media (min-height: 1440px) and (min-width: 2200px) {
                                        top: 25%;
                                        max-height: 45vh;
                                    }
                                }

                                .title-synopsis p {
                                    margin-top: 0;
                                    margin-bottom: 1rem;
                                    font-weight:400;
                                    line-height: 1.5;
                                    flex-grow: 1;
                                }

                            }

                            .talent {
                                height: 82vh;
                                overflow-y: scroll;
                                overflow-x: hidden;

                                h3 {
                                    font-size: 17.5px !important;
                                    margin-bottom: 5px;
                                    margin-top: 0;
                                    line-height: 21px;
                                }

                                h5 {
                                    color: #9f9b9c;
                                    font-size: 12.5px !important;
                                    margin-bottom: 5px;
                                    margin-top: 0;
                                    line-height: 15px;
                                }

                                //same properties we use in WBTVD for talents
                                .responsive-container {
                                    position: relative;
                                    width: 160px;
                                    background: transparent;
                                }

                                .vertical-spacer {
                                    padding-top: 100%;
                                }

                                .img-container {
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    font-size: 16px;
                                    text-align: center;
                                }

                                .img-container:before {
                                    content: ' ';
                                    display: inline-block;
                                    vertical-align: middle;
                                    height: 100%;
                                }

                                .img-container img {
                                    vertical-align: middle;
                                    display: inline-block;
                                    max-width: 100%;
                                    max-height: 100%;
                                    width: auto;
                                    height: auto;
                                }

                                .talent-list .row {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-left: -15px;
                                    margin-right: -15px;

                                    .col-3 {
                                        position: relative;
                                        padding-left: 15px;
                                        padding-right: 15px;
                                        flex-basis: 25%;
                                        flex-grow: 0;
                                        flex-shrink: 0;
                                    }

                                    .col-5 {
                                        position: relative;
                                        padding-left: 15px;
                                        padding-right: 15px;
                                        flex-basis: 41.66%;
                                        flex-grow: 0;
                                        flex-shrink: 0;
                                    }
                                }
                            }
                        }
                    }

                    .title-menu-wrapper {
                        margin-top: 5%;
                        padding-top: 5%;
                    }
                    .title-menu {
                        margin-bottom:3rem;
                    }
                    .title-menu > li {
                        font-size: 150%;
                        position:relative;
                        color: #9f9b9c;
                        padding: 0 10px;
                        cursor: pointer;
                    }
                    .title-menu > li.active {
                        color: #FFF;
                    }
                    .title-menu > li::after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transition: all .1s linear;
                        border-bottom: 3px solid #FFF;
                        width: 0%;
                        margin-left: 0%;
                    }
                    .title-menu > li.active::after {
                        width: 60%;
                        margin-left: -30%;
                    }
                    .title-bg {
                        background-size: 100% auto;
                        background-repeat: no-repeat;
                        background-position: top left;
                        background-color: #280001;
                    }
                    .title-video {
                        height: 300px;
                        position: relative;
                    }
                    .title-play-button {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        cursor: pointer;
                        outline: none;
                        top: 15% !important;
                        left: 5% !important;
                        text-align: center !important;
                    }
                    .icon-play {
                        height: 20%;
                        width: auto;
                    }
                    .title-video-name {
                        color: #FFF;
                        padding: 20px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        text-shadow: 0px 0px 20px #000;
                    }
                    .title-video-name small {
                        display:block;
                        font-size: 65%;
                        color: #777;
                        font-weight: 400;
                    }
                    .title-video video {
                        width:100%;
                    }

                }
            }

            .carousel-indicators {
                align-items: center;
                bottom: 0;
                display: flex;
                justify-content: center;
                left: 0;
                list-style: none;
                margin-left: 15%;
                margin-right: 15%;
                padding-left: 0;
                position: absolute;
                right: 0;
                z-index: 15;


                li {
                    // Default size.
                    border-radius: 0;
                    display: list-item;
                    box-sizing: content-box;
                    flex-basis: auto;
                    flex-grow: 0;
                    flex-shrink: 1;
                    height: 6px;
                    line-height: 22.5px;
                    font-size: 15px;
                    text-align: left;
                    width: 6px;
                    margin: 7px;
                    border-right: none;
                    border-left: none;
                    opacity: 0.5;
                    background-clip: padding-box;
                    background-color: #fff;


                    // Bigger when active.
                    &.active {
                        height: 12px;
                        width: 12px;
                        border-radius: 0px;
                        opacity: 1;
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                    }

                    // Mid-size when hover (except the active one)
                    &:hover:not(.active) {
                        height: 10px;
                        width: 10px;
                    }
                }
            }

            .carousel-control-prev {
                align-items: center;
                bottom: 0;
                display: flex;
                justify-content: flex-start;
                left: 0;
                outline-style: none;
                position: absolute;
                top: 0;
                width: 10%;

                .carousel-control-prev-icon {
                    background-image: none;
                    border-color: transparent #fff transparent transparent;
                    border-style: solid;
                    border-width: 32px;
                }
            }

            .carousel-control-next {
                align-items: center;
                bottom: 0;
                display: flex;
                justify-content: flex-end;
                outline-style: none;
                position: absolute;
                right: 0;
                top: 0;
                width: 10%;

                .carousel-control-next-icon {
                    background-image: none;
                    border-color: transparent transparent transparent #fff;
                    border-style: solid;
                    border-width: 32px;
                }
            }
        }
    }
}
