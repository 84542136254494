/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * billboard.less
 */
@import '../../../styles/app/utils/utils.less';

/**
 * Carousel image
 */
.billboard-container {
    .billboard-overlay {
        z-index: 2
    }

    .carousel-inner {
        > .item {
            background-color: @color-secondary;
        }
        @media screen and (max-width: @screen-md-min) {
            > .item { height: 100%; }
        }
    }

    .carousel-image, .carousel-video {
        width: 100%;
    }
}

.billboard-foreground {
    position: relative;
    top: 0!important;
}

.homepage-billboard .video-banner-container {
    position: relative;
}

.homepage-billboard .video-banner-btns {
    position: absolute;
    top: -90px;
    right: 25px;
}

.homepage-billboard .video-banner-btns button {
    background: rgba(0,0,0,0.5);
    color: #FFF;
    cursor: pointer;
    border-radius: 40px;
    font-size: 40px;
    margin-right: 75px;
	outline: none;
    padding: 15px;
    z-index:100;
}
