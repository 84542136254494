.document-modal {
    canvas {
        margin: auto;
        display: block;
    }
    .pdf-document-render {
        overflow: scroll;
    }

    .close {
        font-size: 28px;
    }

    .modal-download-link {
        float: right;
        font-size: 20px;
        margin-right: 24px;
    }

    .annotationLayer {
        height: 1vh !important;
    }
}
