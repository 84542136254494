.op_container_products {
	.products-header {
		background-image: url('products/prod-header-bg.jpg');
	}

	a.thumbnail-title, a.thumbnail-title:hover {
		font-weight: bold;
		color: #0099ee;
		cursor:pointer;
		font-size: 120%;
	}
	.thumbnail-container img.thumbnail-border{
		border: 3px solid #cccccc;
	}
}
