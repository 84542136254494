/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.fyc-body {
    font-family: "Roboto", sans-serif !important;
    font-size: 15px !important;
    line-height: 22px !important;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #0f0f0f;
}

.fyc-body .row {
    background-color: #0f0f0f;
}

.fyc-header-inner-bg {
    background-color: #1e1e1e;
    border-bottom: solid 1px #ebebeb;
    border-bottom-color: #404040;
    color: #ddc787 !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    transition: background .3s ease-in-out;
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    -ms-transition: background .3s ease-in-out;
    -o-transition: background .3s ease-in-out;
}

.fyc-logo-img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.fyc-logo-container {
    display: block;
    height: 100%;
    line-height: 81px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
    -webkit-backface-visibility: hidden;
    width: 1240px;
    z-index: 99991;
}

.fyc-form-container {
    padding-top: 81px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

@media handheld, only screen and (max-width: 1260px) {
    .fyc-form-container {
        width: 95%!important;
    }
}

@media handheld, only screen and (max-width: 1024px) {
    .fyc-form-container {
        width: 100% !important;
        margin-bottom: 0;
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media handheld, only screen and (max-width: 900px) {
    .fyc-form-container {
        width: 100%!important;
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media handheld, only screen and (max-width: 767px) {
    .fyc-form-container {
        padding-left: 30px!important;
        padding-right: 30px!important;
    }
}

@media only screen and (max-width: 641px) {
    .fyc-submit button {
        font-size: 1em;
        margin: 0 0 16px;
        line-height: 2;
        min-height: 2rem;
        width: 100%;
    }
}


.fyc-form-container .row {
    margin-top: 16px;
}

.fyc-form-label h1 {
    text-transform: uppercase;
    color: #ddc787;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 28px;
}

.fyc-main-form {
    font-size: 125% !important;
}

.fyc-main-form span, .fyc-main-form .checkbox label a {
    color: #ddc787;
}

.fyc-main-form .control-label>span, .fyc-main-form label, .fyc-main-form .territory-select input, .territory-select .css-xp4uvy, .fyc-main-form .checkbox label {
    color: #fff;
}

.fyc-main-form input {
    font-size: inherit;
}

.fyc-main-form .form-control {
    height: 50px;
    padding: 12px 12px 10px !important;
}

.fyc-main-form .form-group, .fyc-main-form .territory-select {
    margin-bottom: 15px;
}

.fyc-main-form .form-group input, .territory-select .css-vj8t7z, .territory-select .css-vj8t7z:hover, .territory-select .css-2o5izw, .territory-select .css-2o5izw:hover {
    background-color: rgba(255,255,255,.1);
    border: solid 1px #e3e3e3;
    border-color: #d4a728;
    box-shadow: 0 3px 0 0 #000;
    color: #FFF !important;
    padding: 12px 12px 10px !important;
}

.territory-select .css-15k3avv {
    background: #000;
    color: #FFF;
}

.territory-select option, .fyc-submit button  {
    background-color: #a0873e;
}

.fyc-form-container .row.agree-terms-checkbox {
    margin-top: 0;
}
.fyc-form-container .row.agree-terms-checkbox .checkbox {
    margin-top: 2px;
}

.fyc-submit {
    padding: 16px 0 10px;
    margin: 16px 0 0;
    clear: both;
    width: 100%;
}

.fyc-submit button {
    border: none;
    box-shadow: 0 3px 0 0 #000;
    color: rgba(255,255,255,1)!important;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    width: 100%;
}

.fyc-submit button:hover,
.fyc-submit button:active,
.fyc-submit button:focus,
.fyc-submit button:active:focus,
.fyc-submit button:active:hover,
.fyc-submit button[disabled]:hover,
.fyc-submit button[disabled]:active   {
    background: #b0974e!important;
    border-color: #d4a728!important;
}

footer.copyright.fyc-copyright {
    margin-top: 70px;
    font-size: 12px;
    color: rgba(255,255,255,0.4);
    background-color:  #333;
    text-align:center;
    .container {
        max-width: 1024px;
        margin: 0 auto;
        text-align:left;
    }
}



.fyc-copyright a {
    color:#aaa;
    border-bottom: solid 1px rgba(255,255,255,0.07);
    padding-bottom: 2px;
}
