/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.clip-list-thumbnail {
    position: relative;
    width: 100px;
    &:hover {
        .video-play {
            top: 34px;
        }
    }
    .img-responsive {
        position: absolute;
        top: 0;
        left: 0;
    }
    .hover-play-icon {
        margin-left: -20px;
        margin-top: -30px;
        font-size: 40px;
        opacity: 0.5;
    }
}
