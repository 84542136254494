/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.tableWrap {
    overflow-x: scroll;
}

.typeBadge {
    display: inline-block;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1;
    font-weight: bold;
    border-radius: 4px;
    padding: 3px 6px;
}

.badgeAuto {
    border: 1px solid #0099EE;
    color: #0099EE;
}

.badgeReel {
    border: 1px solid #777;
    color: #777;
}
