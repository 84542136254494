/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.has-feedback label ~ .form-control-feedback {
  top: 3px;
}

.dropdown-lang-select {
    a:visited,
    .btn-link,
    .btn-link:hover,
    .btn-link:focus {
        cursor: pointer;
        color: #000;
        text-decoration: none;
    }
    input:-internal-autofill-previewed {
        box-shadow: 0 0 0 0.2rem rgba(181, 139, 65, 0.5);
    }

    .dropdown-menu {
        .dropdown-item {
            padding: 5px;
            cursor: pointer;

            &:hover, &.active {
                background-color: #e9e9e9;
            }
        }

        &.login-dropdown {
            width: 190px;
            height: 200px;
            overflow: auto;
        }
    }
}
