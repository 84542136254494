#app-notes {
    box-sizing: border-box;

    .currently-downloading {display: none;}
    .downloading .currently-downloading {
    /*		display: block; */
        text-align:center;
        padding-bottom: 25px;
    }

    #notice {
        border: 2px solid #E50606;
        border-radius: 8px;
        padding: 22px 64px;
        margin:45px auto !important;
        width: 800px;
        color: #E50606;
    }

    #notice h3 {
        background: transparent url("https://www.wbtvd.com/page/full-episodes/notice.png") no-repeat scroll center left;
        background-size: auto 29px;
        font-weight: 500;
        font-size: 34px;
        padding-left: 42px;
        margin:0 0 10px;
    }
    .large-text {
        font-weight: 500;
        font-size: 48px !important;
        text-align:center;
    }
    #notice p {
        font-weight: 200;
        font-size: 18px;
        line-height: 28px;

    }

    .currently-downloading img { margin:0 auto; max-width:510px; width:100%; }

    #steps {
        width:100%;
        max-width:1024px;
        margin: 80px auto;
    }

    .step {
        width:50%;
        float:left;
        background-color: transparent;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 512px auto;
    /*		padding-top: 320px;
        height: 128px;*/
        height: 448px;
        text-align:center;
        font-size: 26px;
        position:relative;
    }

    @media (max-width:480px) {
        .step {
            width:100%;
            float:none;
            max-width:512px;
            margin:0 auto;
        }
    }

    .step img {
        width:100%;
    }
    .step > div {
    /*		position: absolute;*/
    /*		top:320px;*/
        margin-top:-50px;
        height:128px;
        width:100%;
    }

    /*	.step1 { background-image: url("https://www.wbtvd.com/page/full-episodes/step1.png"); }*/
    /*	.step2 { background-image: url("https://www.wbtvd.com/page/full-episodes/step2.png"); }
    .step3 { background-image: url("https://www.wbtvd.com/page/full-episodes/step3.png"); }
    .step4 { background-image: url("https://www.wbtvd.com/page/full-episodes/step4.png"); }
    .step5 { background-image: url("https://www.wbtvd.com/page/full-episodes/step5.png"); }
    .step6 { background-image: url("https://www.wbtvd.com/page/full-episodes/step6.png"); }*/

    .step .num { color:#42b8dc; font-weight:bold; }

    #notice p,
    #notice h3,
    .large-text,
    .step {font-family:"HelveticaNeue","Helvetica Neue",Helvetica,Arial,sans-serif;}

    .globalNav, #top {display:none;}
    #notice {margin-top:0;}
    .large-text {font-size:32px;}

}



