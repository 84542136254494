/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

@import "../styles/bootstrap/variables.less";

.video-overlay-bg {
  z-index: -1 !important;
}

.disclaimer-overlay {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  background: rgb(0, 0, 0);
  z-index: 100000;
}

.disclaimer-overlay h3 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  color:white;
  margin: 0;
  padding:5vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: calc(1vh + 1vw);
}

.disclaimer-timer {
  position: absolute;
  right: calc(1vh + 1vw);
  bottom: calc(1vh + 1vw);
  color: #FFF;
  font-size: calc(0.5vh + 0.5vw);
}

@media (max-width: 991px) {
  .disclaimer-overlay {
      height: 100%;
  }
}

/* not white, should highlight */
.white:hover, .white:focus,
.video-overlay .video-thumbnail-tooltip:focus {
  color: #09E !important;
}

.btn.video-overlay-clipping:disabled {
  span {
    color: @btn-link-disabled-color;
  }
}

.video-overlay-clipping-on {
  span {
    color: @brand-yellow;
  }
}

.video-overlay .video-thumbnail-tooltip {
  box-sizing: border-box;
  height: 40px;
  line-height: 1.2;
  margin: 0;
  padding: 10px 13px 10px 10px;
  top: 1px;
  width: 40px;
}
