.op_container_network_45 {
	width: 978px !important;
	box-sizing: border-box;

	.pageWrapper{width:auto;}
	#top, .opentempl_htmlcontent #op_content {max-width:978px;margin: 0 auto;}

	.globalNav{display:none;}
	.header{height:79px;}

	#op-header-img {
		background: #000618 url("https://www.wbtvd.com/page/network45/Network45_header_960x150.jpg") no-repeat scroll top center;
		background-size:contain;
		height:0;
		margin:0;
		padding-top:15.625%;
	}
	@media (min-width:960px){
		#op-header-img {
			height:150px;
			padding:0;
		}
	}

	#network45-header-img {
		background: transparent url("https://www.wbtvd.com/page/network45/header_silver_no_btn.jpg") no-repeat scroll top center;
		background-size:contain;
		height:0;
		margin: 0 auto;
		max-width: 978px;
		padding-top:26.042%;
		position:relative;
	}
	#header-btns {
	bottom: 10%;
	position: absolute;
	right: 0;
	width: 50.25%;
	left: 45%;
	margin: 0 auto;
	max-width:257px;
	}

	a.btn-header {
		display: block;
		background: transparent no-repeat scroll top center;
		background-size: contain;
		text-indent: -99999px;
		max-width:257px;
		width:100%;
		height:0;
		padding-top:14.008%;
		margin-bottom:10px;
	}
	a.btn-header.avail {
		background-image: url("https://www.wbtvd.com/page/network45/btn_silver_avail.png");
	}
	a.btn-header.reel {
		background-image: url("https://www.wbtvd.com/page/network45/btn_silver_reel.png");
	}
	a.btn-header.reel[href='#'] {
		display:none;
	}
	@media (min-width:960px){
		#network45-header-img {
			height:250px;
			padding:0;
		}
		a.btn-header {
			height:36px;
			padding:0;
			margin-bottom: 10px;
		}
	}

	/*	#opentempl_player {display:none;}*/

	.content_container {
		width: 25%;
		float:left;
		padding: 10px 0.25em 1em;
		margin: 0;
	}
	.content_container a.poster,
	.content_container a.poster:focus
	.content_container a.poster:hover {
		display:block;
		margin: 0 auto;
		height:0;
		padding-top:127.75%;
		width: 100%;
		max-width:227px;
		text-decoration:none;
		background: transparent no-repeat scroll center center;
		background-size: cover;
		border:1px solid #999;
		text-indent: -99999px;
	}

	.content_container img {
		width: 100%;
		height: auto;
		margin: 0 auto;
		max-width: 100%;
	}

	.footer ul {margin:32px 0 0;width:25%;}
	.footer ul > li {
	padding-left: 10px;
	}

	.btns {
	margin: 5px auto 0;
	max-width: 227px;
	clear:both;
	}

	a.btn {
		display: block;
	/*		border:1px solid #fff;*/
		background: transparent  no-repeat scroll top center;
		background-size: contain;
		text-indent: -99999px;
		border-radius: 2px;
	}
	.btn.btn-info {
		background-image: url("https://www.wbtvd.com/page/network45/btn_silver_info.png");
		width:44.75%;
		float:left;
	}
	.btn.btn-pdf {
		background-image: url("https://www.wbtvd.com/page/network45/btn_silver_pdf.png");
		width:44.75%;
		float:left;
	}
	.btn.btn-trailer {
		background-image: url("https://www.wbtvd.com/page/network45/btn_silver_trailer.png");
		width:55.25%;
		float:right;
	}
	a.btn span {
		display:block;
		width:100%;
		height:0;
	}
	a.btn.btn-info span,
	a.btn.btn-pdf span{
		padding-top:34.34%;
	}
	a.btn.btn-trailer span {
		padding-top:27.87%;
	}


	/* Medium Devices, Desktops */
	@media only screen and (max-width : 977px) {

		.pageWrapper, .footerWrapper {width:100%;}
		.globalNav {display:none;}
		.internal_links {clear:both;}
	}

	/* Small Devices, Tablets */
	@media only screen and (max-width : 767px) {
		.content_container {width: 33%;}
		.globalSearch {display:none;}
		.globalNav ul {width:auto;}
		.globalNav {display:none;}

		a.btn-header{margin-bottom:2.5%;}

	/*		.header {height:79px;}*/
		.header h1 {float:none;margin:0 auto;width:95%;max-width:418px;}
		.header h1 a {width:100%;background-size:contain;}
		.userLogdIn {display:none;}
	}

	/* Extra Small Devices, Phones */
	@media only screen and (max-width : 479px) {
		.content_container {
			width: 50%;
		}
		#op-header-img {
			background-image: url("https://www.wbtvd.com/page/network45/NATPE_Header_SM_640x150.jpg");
			height:0;
			padding-top:23.43%;
		}
		.content_container a {
	/*			max-width:204px;*/
		}
		a.btn-header{margin-bottom:1%;}

		.header {width:90%;float:none;margin:0 auto;clear:both;display:table;height:auto;}
		.header h1 {width:100%;text-align:center;}
		.header h1 a {height:0;padding-top:10.28%;margin:0 auto;}
		.opentempl_htmlcontent > h2 {margin-top:0.5em;}


		.footer ul {margin:32px 0 0;width:50%;}
		.footer ul > li {padding-left:10px;}
		.footerWrapper {background-size:100% 110%;}
		.footer .global_links,.footer .internal_links {display:table;clear:both;}

	}

	/* Custom, iPhone Retina */
	@media only screen and (max-width : 320px) {
		.content_container {
			width: 50%;
		}
		.footerWrapper {
			display:none;
		}
	}

	.copyright p {width:100%;}



	/* VIDEO PLAYER */
	#opentempl_top {
		display: none;
		width: 100%;
		height: 100%;
		height: auto;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0,0,0,.9);
		z-index: 999;
	}

	.opentempl_video_player {
		background-color: rgba(0,0,0,1);
	}

	#opentempl_vsplayer {
		display: block;
		background-color: rgba(0,0,0,1);
		position: absolute;
		width: 760px;
		height: 430px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
	}

	#opentempl_top .btn-close {
		background: url("https://www.wbtvd.com/page/network45/overlay_close.png") no-repeat scroll center center rgba(0, 0, 0, 0);
		height: 39px;
		opacity: 0.5;
		position: absolute;
		right: 40px;
		top: 30px;
		width: 39px;
	}

	#opentempl_top .btn-close:hover {
		opacity: 1;
	}
}
