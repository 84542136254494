@import "../../styles/bootstrap/variables.less";

.slider-filler {
    background-color: @brand-yellow;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}
.slider-handle {
    background-color: @brand-yellow;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 4px;
    height: 20px;
    border-radius: 3px;
}
.slider-handle:hover {
    z-index: 2;
}
