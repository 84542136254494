/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * header.less
 */

@import '../../styles/app/utils/utils.less';

// Color definitions
@sky-blue:  #04006c; // Navbar background
@indigo: #5128F2;
@sapphire: #0022af;
@gray-blue: #538cc6;
@regular-blue: #015cfe;

// Header
#layout-header {
    background-color: @sky-blue;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 5px;
    width: 100%;
    height: 70px;
    z-index:1200;

    .header-item {
        justify-self: left;

        height: auto;
        line-height: 70px;

        &.brands-nav-menu {
            cursor: pointer;
            width: 44px;
            text-align: center;

            // This is the toggle button, no better way to pinpoint it.
            .brands-nav-menu-toggle {
                color: #ffffff;
                display: block;
            }

            &.open a:focus {
                outline: 5px auto -webkit-focus-ring-color;
            }

            .dropdown-menu {
                background-color: @sapphire;
                margin-top: -1px;
                width: 100vw;
                height: 0;
                left: -5px;
                overflow-x: scroll;

                h4 {
                    color: #ffffff;
                    padding: 20px 20px 0 10px;
                    margin: 0;
                    cursor: default;
                }

                .brand-logo {
                    display: inline-block;
                    padding: 10px;
                    height: 90px;
                    width: 160px;

                    .brand-image-container {
                        display: block;
                        padding: 10px;
                        height: 100%;
                        width: 100%;
                        background-color: @gray-blue;

                        &.active, &:hover {
                            background-color: @regular-blue;
                        }

                        div {
                            display: block;
                            height: 100%;
                            width: 100%;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                        }
                    }
                }
            }

            &.open {
                .dropdown-menu {
                    height: auto;
                }
            }
        }

        &.brand-logo {
            img {
                height: 50px;
            }
        }

        &.header-actions {
            margin-left: auto;

            a {
                color: #ffffff;

                &.active, &:focus, &:hover {
                    background-color: @regular-blue;
                }
            }

            .open > a {
                background-color: @regular-blue;
            }

            .dropdown-menu {
                li a {
                    color: #333333;

                    &:focus, &:hover {
                        color: #262626;
                        background-color: #f5f5f5;
                    }
                }
            }
        }

        .brand-nav-icon {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-family: inherit;
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 24px;
        }
    }

    h1 {
        margin: 0;
    }
}

.change-language-modal {
    .dropdown-menu {
        border-radius: 3px;
        .dropdown-item {
            padding: 5px;
            cursor: pointer;

            &:hover, &.active {
                background-color: #e9e9e9;
            }
        }
    }
}

@media (max-width: 768px) {
    #layout-header {
        .header-item.brands-nav-menu .dropdown-menu .brand-logo a img {
            height: 40px;
        }

        .navbar-collapse.collapsing, .navbar-collapse.collapse.in {
            background-color: @sky-blue;
            position: absolute;
            top: 70px;
            left: 0px;
            right: 0px;

            .dropdown-menu {
                background-color: #555555;

                li a {
                    color: #eeeeee;
                }
            }
        }
    }
}

.header-search-icon {
    transition: transform (@animation-speed * 2) ease;
    transform: translate3d(0, 0, 0);
    &.icon-hidden {
        transform: translate3d(0, -@header-height, 0);
    }
    .search-icon {
        cursor: pointer;
        padding: (@offset * 1.75) @offset;
    }
}

.header-nav-requests {
    display: inline-block;
    background: @indigo;
    padding: 10px;
    margin-top: -10px;
    margin-bottom: -10px;
}

.header .dropdown-menu {
    z-index: 1400; // Let it open above the alert.
}

/* Happy Fox Modal */
.happyfox-iframe-container {
    background-color: rgba(0,0,0,.4);
    display: flex;
    height: 100%;
    left: 0;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}
.happyfox-iframe-container .closeBtn {
    color: #4b4b4b !important;
    background: transparent !important;
    cursor: pointer;
    display: none;
    position: absolute;
    right: 31%;
    margin-top: 5%;
}
.happyfox-iframe {
    height: 70%;
    width: 42%;
    margin-top: 3%;
}
img.cart-items-loader {
    display: inline;
}
/* Retina Display */
@media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (-o-min-device-pixel-ratio: 13/10) {
       .happyfox-iframe-container .closeBtn {
           right: 31%;
       }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (-o-min-device-pixel-ratio: 13/10) {
       .happyfox-iframe-container .closeBtn {
           right: 31%;
       }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 13/10) {
       .happyfox-iframe-container .closeBtn {
           right: 31%;
       }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 13/10) {
       .happyfox-iframe-container .closeBtn {
           right: 31%;
       }
}
/* Small devices (up to 768px) */
@media (max-width: @screen-phone) {
    .happyfox-iframe {
        width: 90%;
        height: 70%;
        margin-top: 3%;
    }
    .happyfox-iframe-container .closeBtn {
        right: 10%;
        margin-top: 5%;
    }
}
/* Small devices (from 768px) */
@media (max-height: @screen-phone) and (orientation: landscape) {
    .happyfox-iframe {
        width: 80%;
        height: 90%;
        margin-top: 3%;
    }
    .happyfox-iframe-container .closeBtn {
        right: 13%;
        margin-top: 5%;
    }
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .happyfox-iframe {
        width: 80%;
        height: 80%;
        margin-top: 3%;
    }
    .happyfox-iframe-container .closeBtn {
        right: 12%;
        margin-top: 5%;
    }
}
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .happyfox-iframe-container .closeBtn {
        right: 31%;
    }
}
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) and (orientation: landscape) {
    .happyfox-iframe-container .closeBtn {
        right: 31%;
    }
}
@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) and (orientation: landscape) {
    .happyfox-iframe-container .closeBtn {
        right: 31%;
    }
}

/* navbar links collapse according to partner */
@media (min-width: @screen-sm) {

    .wbtvd-header {
        @media (max-width: @screen-md) {
            .navbar-toggle {
                display: block;
            }
            .collapsed-search {
                top: 20px !important;
                position: absolute;
                right: 50px;
                font-size:18px;
                background: transparent;
                border:none;
                display: block !important;
            }
            .collapsed-search .search-icon {
                top: 4px;
            }
            .navbar-collapse {
                &.collapse {
                    display: none !important;
                }
                &.in {
                    display: block !important;
                }
            }
            .navbar-collapse.collapsing, .navbar-collapse.in {
                background-color: @sky-blue;
                position: absolute;
                top: 70px;
                left: 0px;
                right: 0px;
            }
            .header-search-icon {
                display: none !important;
            }
        }
    }

    .adultswim_press-header,
    .cartoon_network_press-header,
    .cartoonito_press-header,
    .wbtvd_press-header,
    .hbo-header {
        @media (max-width: @screen-lg) {
            .navbar-toggle {
                display: block;
            }
            .collapsed-search {
                top: 20px !important;
                position: absolute;
                right: 50px;
                font-size:18px;
                background: transparent;
                border:none;
                display: block !important;
            }
            .collapsed-search .search-icon {
                top: 4px;
            }
            .navbar-collapse {
                &.collapse {
                    display: none !important;
                }
                &.in {
                    display: block !important;
                }
            }
            .navbar-collapse.collapsing, .navbar-collapse.in {
                background-color: @sky-blue;
                position: absolute;
                top: 70px;
                left: 0px;
                right: 0px;
            }
            .header-search-icon {
                display: none !important;
            }
        }
    }
}
