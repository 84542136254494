/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@bootstrap-framework-path: '../bootstrap';

// Core variables and mixins
@import "@{bootstrap-framework-path}/variables.less";
@import "@{bootstrap-framework-path}/mixins.less";

@import "./utils/utils";
@import "./atomic";

// WBTVD Specific styles
@import "./app-buttons";
@import "./app-animations";

// Component fixes
@import "./base-select/base-select";

html {
    // font-size: 10px;
    // -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.col-inline {
    display: inline-block;
    vertical-align: top;
    float: none!important;
}

footer {
    &.copyright {
        background-color: @footer-copyright-bg;
        color: #FFF;
        padding: (@offset * 1.5) @offset;
        text-align: center;
    }
}

/** forms.less */
.form-control:not(.input-lg) {
    height: @select-input-height; // normalize height between inputs and selects
}

/** panels.less */
.panel-clean {
    box-shadow: none;
    .panel-variant(transparent; @panel-default-text; transparent; transparent);
}

// Search
.search-criteria-input {
    width: 320px;
}

// Thumbnail Tooltips
.gallery-tooltip {
	opacity: 1;
}
.gallery-tooltip-inner {
	background-color: #FFFFFF !important;
	box-shadow:0px 0px 10px black;
    margin: 0 !important;
    padding: 0 !important;
}
.gallery-tooltip-inner a {
	display: block;
    padding: 8px 12px;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    color: #000000 !important;

    &:hover {
        background-color: #E9E9E9;
    }
}
.gallery-tooltip-arrow {
	border-bottom-color: #FFF !important;
}

.react-datepicker {
  font-size: 1em;
}

.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__current-month {
  font-size: 1em;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
  left: 1em;
}

.react-datepicker__navigation--next {
  right: 1em;
}

.react-datepicker__input-container {
    display: block;
}

.react-datepicker-wrapper {
    display: block;
}

.react-datepicker__close-icon::after {
    margin: 0 auto 0;
}

.react-datepicker-popper {
    z-index: 1000 !important;
    margin-left: 25%;
}

a[disabled] {
    pointer-events: none;
}

// Header Navigation Items
.header .navbar-nav > li > a.active {
    background: #555555;
}

// Remove notification button in Edit Account -> Onboarding tab.
.remove-notification {
    margin-top: 28px;
}

.vertical-image-height {
    min-height: 230px;
}

.index-fix {
    z-index: 10000;
}

.gray-text {
    color: #767676;
}

// Asset list, document name column ellipsis
.list-overflow {
    max-width: 100px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

td.row-reorder-handle {
    cursor:move;
}
td.row-reorder-handle:first-child {
    background: url(../images/reorder-handle.png) no-repeat 3px 50%!important;
    padding-left:10px;
}
.ReactTable .rt-td.row-reorder-handle{
    cursor: move;
}
.ReactTable .rt-td.row-reorder-handle:first-child{
    background: url(../images/reorder-handle.png) no-repeat 3px 15px !important;
    padding-left: 10px;
}

@media (min-width: @screen-sm-min) {
    .list-overflow {
        max-width: 400px;
    }
}

@media (min-width: @screen-md-min) {
    .list-overflow {
        max-width: 500px;
    }
}

@media (min-width: @screen-lg-min) {
    .list-overflow {
        max-width: 900px;
    }
}

.alert-container {
    position: fixed;
    z-index: 1200;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 80px;
    background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: @screen-sm-min) {
    .alert-container {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 80px;
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.alert-container .alert {
    width: 100%;
}

.amp-logo {
    display: none !important
}

.amp-default-skin {
    .vjs-caption-settings, .vjs-caption-settings .vjs-tracksetting select,
    .vjs-default-button, .vjs-done-button {
        outline: none !important;
    }
    .vjs-caption-settings:focus, .vjs-caption-settings .vjs-tracksetting select:focus,
    .vjs-default-button:focus, .vjs-done-button:focus {
        box-shadow: 0 0 6px 6px #307FCF;
    }
    .vjs-tracksettings-controls {
        padding: 10px;
    }
}

.dropdown-toggle.disabled {
    cursor: default;
    opacity: 0.5;
}

@media (min-height: 500px) {
    .main-style {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
    .page-content {
        flex: 1 0 auto;
    }
}

.text-align-center {
    text-align: center;
}

.label-default-outline {
    border: 1px solid #777;
    color: #777;
}

.label-primary-outline {
    border: 1px solid #0075ca !important;
    color: #0075ca;
}

.label-gray {
    background: #dedede;
    color: #616161;
    margin: 0 3px;
    cursor: help;
}

em.highlighted {
    color: #0099ee;
    font-weight: bold;
    font-style: normal;
}

table.vertical-align-middle tr>td {
    vertical-align: middle;
}

.alert-password-reset {
    background-color: #d5d5d5;
    border-color: #bbbbbb;
    color: #525252;
}

.alert-password-reset-additional-requirements {
    color: #525252;
}

/*
* tooltip
*/

.parcel-tooltip {
    position: relative;
    display: inline-block;
}

.parcel-tooltip .tooltip-inner {
    visibility: hidden;
    width: 140px;
    max-width: 270px !important;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    position: absolute;
    z-index: 1;

    bottom: 100%;
    left: 50%;
    margin-left: -70px;
    margin-bottom: 10px;
    word-break: initial;
}
.parcel-tooltip .tooltip-inner::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}
.parcel-tooltip:hover .tooltip-inner {
    visibility: visible;
}
.collapse-button {
    margin-left: -0.3%;
    cursor: pointer;
    font-size: 15px;
}

.release-dates-table {
    position: relative;
    overflow: hidden;
}

.release-dates-table.collapsed {
    height: 50px;
}

.release-dates-table.collapsed::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
    z-index: 1;
}

.release-dates-table.collapsed tr:not(:first-child) {
    display: none;
}

.header-item.brand-logo img {
    display: inline-block;
}
