/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * home.less
 */

h1, h2, h3, h4 {
    .btn {
        margin-left: 1em;
    }
}

.grid-container {
    > h2 {
        display: inline-block;
    }

    h3 {
        display: inline-block;
    }

    .btn {
        margin: 0 0 1em 1em;
    }
}

.news-header {
    > span, + div .total, + div .weekly {
        color: inherit;
        display: inline-block;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
    }

    > span, + div .weekly {
        font-size: 24px;
    }

    + div {
        .total, .weekly {
            display: block;
        }

        .total {
            font-size: 18px;
        }
    }
}

.footer-news {
    small {
        color: #6c6c6c;
    }

    .total a {
        color: #006ec3;
    }
}
