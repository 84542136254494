/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * Login Page.
 */

@import '../../styles/app/utils/utils.less';

/** Makes an element go full size (x & y) */
.full-size {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.login-page:extend(.full-size) {
    header h1 img {
        margin: 0 auto; /* Centers the brand logo above the login form */
    }
    background-color: #222;
    .content {
        position: relative;
        width: 100%;
        z-index: 50;
    }
    #wrapper-top {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 10;
        @media (max-width: 767px) {
            padding-bottom: @offset * 2;
        }
        @media (max-width: 767px) and (orientation:landscape) {
            height: auto;
        }
    }
    // Handle layout positioning
    #wrapper-top {
        display: table;
        .content {
            // padding-top: 10%; // 200px;
            display: table-cell;
            vertical-align: middle;
            @media (max-width: 767px) {
                display: block;
                padding: (@offset * 6) (@offset * 2) (@offset * 6) (@offset * 2);
            }
        }
    }
    .login-form-wrapper {
        padding-top: 0!important;
        padding-bottom: 0!important;
    }
    .form-control {
        height: 40px;
        box-shadow: none;
    }
    .login-links {
        display: block;
        padding: 5px 0 0 0;
        margin-top: 10px;
        color: #666 !important;
        text-align: center;
        font-size: 12px;
    }
    .login-footer {
        text-align: center;
        color: #FFFFFF;
        padding-top: 10px;
    }
}


/**
 * Box content
 */
.form {
    .box {
        padding: 40px (@offset * 2) (@offset * 2) (@offset * 2);
        background: #e9e9e9;
        text-align: left;
        margin-top: 10px;
    }
}

/**
 * Titles
 */
.box {
    h1,h2,h3,h4,h5,h6 {
        margin-top: 0;
    }
    header {
        margin-bottom: 1.5em;
    }
}

.bgimages:extend(.full-size) {
    z-index: 1;
    .bgimage {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }
}

// Image animation
.bgimages {
    .bgimage {
        opacity: 0;
        transition: opacity 1s ease-in;
        z-index: 3;
    }
    .next-image,
    .current-image {
        opacity: 1;
    }
    .next-image {
        z-index: 1;
    }
    .current-image {
        z-index: 2;
    }
}
