/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * =====================================
 * Preloader
 * =====================================
 */
@preloader-size: 50px;
@preloader-color: #AAA;

.preloader {
    position: relative;
    font-size: @preloader-size;
    min-height: @preloader-size * 2;
    max-height: 9999px;
    width: 100%;
    z-index: 100;
    > * {
        color: @preloader-color!important; // Prevents changing by hover from other elements
    }
    &.hidden {
        display: none;
    }
    &.fixed {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        min-height: initial;
        max-height: initial;
    }
    .spinner {
        font-size: 1em;
        height: 1em;
        width: 1em;
        text-align: center;
        position: absolute;
        margin: auto;
        top: 0; right: 0; left: 0; bottom: 0;
    }
    .message {
        display: block;
        position: absolute;
        font-size: 0.5em;
        height: 1.25em;
        line-height: 1.5em;
        text-align: center;
        top: @preloader-size * 1.75;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        padding: 0 20%;
        z-index: 10;
        animation: fadeInMessage 500ms ease;
    }
}

/**
 * Sizes
 */
.preloader {
    &.preloader-sm,
    &.preloader-small {
        font-size: 28px;
    }
    &.preloader-md,
    &.preloader-medium {
        font-size: 42px;
    }
    &.preloader-lg,
    &.preloader-large {
        font-size: 64px;
    }
}

/**
 * Background and colors.
 */
.preloader-background {
    background-color: rgba(255, 255, 255, 0.75);
}

/**
 * Fade In
 */
@keyframes fadeInMessage {
    from { opacity: 0; }
    to { opacity: 1; }
}
