.pitchblue-heading h4
{
	/*text-transform: uppercase;*/
	margin-top: 25px;
	margin-left: 5px;
	/*margin-bottom: -35px;*/
	font-size: 1.4em;
	color: #006A97;
}

.pitchblue-paragraph p
{
	margin-top: 15px;
	margin-left: 5px;
}

.pitchblue-contact p
{
	margin-left: 35px;
}

.pitchblue_end
{
	margin-bottom: 85px;
}
