/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.stillContainer {
    position: relative;
    display: inline-block;
    max-height: 100%!important;
    max-width: 100%;
}

.still {
    background-size: auto;
}

.shareModal {
    background-color: transparent !important;
}

.imageDownloadLink {
    &:hover {
        background-color:#F5F5F5;
    }
}
