.op_container_hd_titles {

    box-sizing: border-box;
    width: 978px !important;
    margin:auto;

    ul {
        margin-left: 40px;
    }

    .hd-video-page ul li {
    padding-top:10px;
    padding-right:10px;
    position: relative;
    overflow: hidden;
    box-sizing:border-box;
    width: 200px;
    }

    .hd-video-page a{
        color:#3193d4!important;
    }


    .hd-video-page ul li:last-child{
        padding-right:0;
        width:190px;
    }

    .hd-videos-container{
        float:right;
        width:790px;
        padding-left:20px;
    }


    .hd-videos-sidebar .heading{
        background: transparent url('https://wbtvd.com/page/hd-titles/left_ser_bg.png') repeat-x scroll 0 0 !important;
        color:#474747!important;
    }
    ul#thetitles.hd-videos-sidebar-list {
        display:inline;
        list-style-type:none;
        height:100%;

    }
    .hd-videos-sidebar-list li{
        font-size:12px;
        padding-left:15px;
    }
    .clear {clear:both;}

    .hd-video-wrapper{
        display:inline-block;
        clear:both;
        position:relative;
        border:1px solid #CCC!important;
    }

    .hd-video-wrapper img{
        width:188px;
        height:auto;
        display:block;
        margin:0;
    }

    .hd-video-title{
        font-size:16px;
        font-weight:bold;
        margin-top:15px;
        display:block;
    }

    .hd-videos-header{
        padding: 0 0 10px;
    }

    .hd-videos-row{
        padding: 0px 0px 60px;
    }

    .hd-videos-header-text{
        font-size:22px;
        font-weight:bold;
        font-color:#474747;
    }

    .hd-videos-header-link{
        display:inline-block;
        padding:5px 30px 5px 10px;
        background: #FFF url('https://wbtvd.com/page/hd-titles/chevron_right.png') no-repeat right center;
        font-size:14px;
        font-weight:bold;
        margin-left:20px;
        border: 1px solid #CCC !important;
        color:#3193d4!important;
        vertical-align: bottom;
    }

    .view-all-hd-titles-link{
        display:inline-block;
        padding:15px 45px 15px 30px;
        background: #FFF url('https://wbtvd.com/page/hd-titles/chevron_right.png') no-repeat right center;
        color:#3193d4!important;
        font-weight:bold;
        font-size:32px;
        border: 1px solid #CCC !important;
    }

    .view-all-hd-titles-link-container{
        text-align:center;
        padding:0 0 60px 0;
    }

    li.atitle{
        float:left;
        list-style-type:none;
        margin:0;
        padding:0;
    }



    #op_content {
        padding:1.75% 0;
    }

    .globalNav{display:none;}
    .header{height:79px;}

    .video-single-width {
        width: 200px;
    }

    h2 {

        padding-top: 5px;

    }
}
