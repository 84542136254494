.op_container_crime_package {
    background: #000;
    box-sizing: border-box;
    width: 978px !important;

    .pageWrapper{width:auto;}
    #top, .opentempl_htmlcontent #op_content, #op_footer {max-width:978px;margin: 0 auto;}

    #op_content {
        padding:1.75% 0;
    }

    .globalNav{display:none;}
    .header{height:79px;}

    #op_header {
        background: #000 url("https://wbtvd.com/page/crime-package/banner_bg_repeat.jpg") repeat-x scroll center center;
    }
    #op-header-img {
        background: transparent url("https://wbtvd.com/page/crime-package/banner_art.jpg") no-repeat scroll top center;
        background-size:contain;
        height:0;
        margin: 0 auto;
        max-width: 978px;
        padding-top:26.042%;
        position:relative;
    }
    #header-btns {
      position: absolute;
      display: flex;
      top: 70%;
      right: 0;
    /*	  width: 50%;*/
      margin: auto;
      left:45%;
      max-width:100%;
    }

    a.btn-header {
        display: inline-block;
        background: transparent no-repeat scroll top center;
        background-size: contain;
        text-indent: -99999px;
        max-width:139px;
        width:30%;
        height:0;

    /*		padding-top:41.727%;*/
        padding-top:12.518%;
        margin:0 0.75% 10px;
    }
    a.btn-header.presentation {
        background-image: url("https://wbtvd.com/page/crime-package/banner_btn_presentation.png");
    }
    a.btn-header.info {
        background-image: url("https://wbtvd.com/page/crime-package/banner_btn_info.png");
    }
    a.btn-header.reel {
        background-image: url("https://wbtvd.com/page/crime-package/banner_btn_sizzlereel.png");
    }
    a.btn-header.reel[href='#'] {
        display:none;
    }
    @media (min-width:960px){
        #op-header-img {
            height:250px;
            padding:0;
        }
        a.btn-header {
            height:58px;
            width:100%;
            padding:0;
            margin-bottom: 10px;
        }
    }

    /*	#opentempl_player {display:none;}*/

    .content_container {
        width: 25%;
        float:left;
        padding: 10px 0.25em 1em;
        margin: 0;
    }

    .content_container a.poster,
    .content_container a.poster:focus
    .content_container a.poster:hover {
        display:block;
        margin: 0 auto;
        height:0;
        padding-top:127.75%;
        width: 100%;
        max-width:227px;
        text-decoration:none;
        background: transparent no-repeat scroll center center;
        background-size: cover;
        border:1px solid #333;
        text-indent: -99999px;
    }
    .content_container a.poster {
        background-color:rgba(255,255,255,0.1);
    }
    .content_container img {
        width: 100%;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
    }

    #op_footer {
        padding: 30px 15px;
        text-align:center;
        color: #aaa;
    }

    .footer ul {margin:32px 0 0;width:25%;}
    .footer ul > li {
      padding-left: 10px;
    }

    .btns {
        margin: 10px auto 5px;
        max-width: 227px;
        clear:both;
    }

    a.btn {
        display: block;
    /*		border:1px solid #fff;*/
        background: transparent  no-repeat scroll top center;
        background-size: contain;
        text-indent: -99999px;
        border-radius: 2px;
        border: 1px solid #000;
    }

    .h2_crime_package {
    color: #c21b1b;
    margin-left: 10px;
    }

    a.btn.disabled {
        opacity:0.5;
        cursor:default;
        pointer-events: none;
    }

    .btn.btn-showinfo {
        background-image: url("https://wbtvd.com/page/crime-package/btn_info.png");
        width:44.75%;
        float:left;
    }

        .btn.btn-seriesinfo {
        background-image: url("https://wbtvd.com/page/crime-package/btn_seriesinfo.png");
        width:100%;
        float:left;
    }

    .btn.btn-trailer {
        background-image: url("https://wbtvd.com/page/crime-package/btn_trailer.png");
        width:55.25%;
        float:right;
    }
    a.btn span {
        display:block;
        width:100%;
        height:24px;
    }
    a.btn.btn-showinfo span,
    a.btn.btn-seriesinfo span,
    a.btn.btn-showinfo span
    a.btn.btn-trailer span {
        padding-top: 5px;
    }


    /* Medium Devices, Desktops */
    @media only screen and (max-width : 977px) {

        .pageWrapper, .footerWrapper {width:100%;}
        .globalNav {display:none;}
        .internal_links {clear:both;}
    }

    /* Small Devices, Tablets */
    @media only screen and (max-width : 767px) {
        .content_container {width: 33%;}
        .globalSearch {display:none;}
        .globalNav ul {width:auto;}
        .globalNav {display:none;}

        a.btn-header{margin-bottom:2.5%;}

    /*		.header {height:79px;}*/
        .header h1 {float:none;margin:0 auto;width:95%;max-width:418px;}
        .header h1 a {width:100%;background-size:contain;}
        .userLogdIn {display:none;}
    }

    /* Extra Small Devices, Phones */
    @media only screen and (max-width : 479px) {
        .content_container {
            width: 50%;
        }
    //     .content_container a {
    // /*			max-width:204px;*/
    //     }

        #op-header-img {
            background-image: url("https://wbtvd.com/page/crime-package/banner_art_mobile.jpg");
        /*	background-position: center bottom; 	*/
        /*	background-size: auto 105%; */
            height:0;
            padding-top:34.4%;
        }

        #header-btns {
            left: 30%;
        }

        a.btn-header{margin-bottom:1%;}

        .header {width:90%;float:none;margin:0 auto;clear:both;display:table;height:auto;}
        .header h1 {width:100%;text-align:center;}
        .header h1 a {height:0;padding-top:10.28%;margin:0 auto;}
        .opentempl_htmlcontent > h2 {margin-top:0.5em;}


        .footer ul {margin:32px 0 0;width:50%;}
        .footer ul > li {padding-left:10px;}
        .footerWrapper {background-size:100% 110%;}
        .footer .global_links,.footer .internal_links {display:table;clear:both;}

    }

    /* Custom, iPhone Retina */
    @media only screen and (max-width : 320px) {
        .content_container {
            width: 50%;
        }
        .footerWrapper {
            display:none;
        }
    }

    .copyright p {width:100%;}

}
