/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * searchbar.less
 */

@import '../../styles/app/utils/utils.less';

.header-search-icon {
    cursor: pointer;
}

.auto-complete {
    padding-left:10px;
    height:40px;
    font-size:24px;
    border:none;
    width: 100%;
}

.header-search .item {
    padding: 3px 20px;
    font-size: 18px;
    line-height: 24px;
}

.menu {
    position: absolute;
    background: white;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    width: auto;
}
@media (min-width: @screen-md-min) {
    .menu {
        width: 45%;
    }
}

.type-header {
    padding: 0 15px;
}

.auto-complete-suggestion {
    padding: 3px 20px;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    color: #fff;
    background-color: #0099EE;
}

.auto-complete-suggestion.autocomplete-cursor {
    color: #fff;
    background-color: #0099EE;

}

.auto-complete-suggestion p {
    margin: 0;
}

.header-search .dropdown-toggle:focus {
    outline: 12px auto -webkit-focus-ring-color;
}
