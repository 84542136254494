.container_2015 {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}

.op_container_2015 {
    width: 960px;
    background-color: transparent;
    margin-bottom: 10px;

    .myrow{
        margin-right: -15px;
        margin-left: -15px;

    }
    .title_header {
        padding-top: 22px;
        padding-bottom: 5px;
        padding-left: 0px;
    }

    .banner {
        height: 399px;
        width: 960px;
    }



    .col-md-4 {
            width: 33.3333%;
                text-align: center;

        }

        .col-sm-6 {
            width:33%;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
    .left_poster {
        padding-top: 22px;
        padding-right: 19px;
        padding-bottom: 22px;

    }

    .center_poster {
        padding-top: 22px;
        /*padding-left: 9px;
        padding-right: 19px;
        padding-bottom: 22px;*/
    }

    .right_poster {
        padding-top: 22px;
        padding-left: 0px;
        padding-bottom: 22px;
    }



    .buttons_download_watch {
        padding-left: 0px;
        margin-top: -10px;
        padding-bottom: 50px;
    }

    .download_pdf_left {
        padding-left: 0px;
        margin-left: -2px;
        margin-top: -5px;
    }

    .watch_trailer_left {
        margin-left: -5px;
        padding-left: 0px;
        margin-top: -10px;
    }

    .buttons_download_watch_mid_right {
        /*padding-left: 0px;*/
        margin-top: -10px;
        padding-bottom: 30px;
    }

    .carousel_correction {
        margin-left: -15px;
    }

    .download_pdf_center {
        margin-left: -5px;
        padding-left: 0px;

    }


    .watch_trailer_center {
        margin-left: -5px;
        padding-left: 0px;

    }

    .download_pdf_right {
        padding-left: 0px;
        margin-left: 0px;
        margin-top: -5px;
    }
    .watch_trailer_right {
        margin-left: -4px;
        padding-left: 0px;
        margin-top: -10px;

    }
}
