.op_container_digital_series {
	width: 960px !important;
	background-color: transparent !important;
	margin-bottom: 20px;

    .title_header {
        padding-top: 22px;
        padding-bottom: 5px;
        padding-left: 0px;
    }

    .banner {
        /*width: 960px;
        padding: 16px;
        background: #D3D3D3;*/
        height: 104px;
        width: 960px;
    }

    .left_poster {
        margin-top: 22px;
        margin-left: -15px;
        width: 227px;
        height: 290px;
    }

    .center_poster {
        margin-top: 22px;
        width: 227px;
        height: 290px;
        margin-left: -11px;
    }

    .right_poster {
        margin-top: 22px;
        padding-left: 0px;
        margin-left: -5px;
        width: 227px;
        height: 290px;
    }

    .extreme_right_poster {
        margin-top: 22px;
        padding-left: 0px;
        margin-left: -3px;
        width: 227px;
        height: 290px;
    }



    .buttons_download_watch {
        padding-left: 0px;
        margin-top: 10px;
        padding-bottom: 30px;
    }

    .download_pdf_left {
        padding-left: 0px;
    }

    .buttons_download_watch_mid_right {
        /*padding-left: 0px;*/
        margin-top: 10px;
        margin-left: -10px;
        padding-bottom: 30px;
    }

    .buttons_download_watch_extreme_mid_right {
        /*padding-left: 0px;*/
        margin-top: 10px;
        margin-left: -4px;
        padding-bottom: 30px;
    }

    .watch_trailer_extreme_mid_right {
        padding-left: 4px;
    }

    .buttons_download_watch_extreme_right {
        /*padding-left: 0px;*/
        margin-top: 10px;
        margin-left: 0px;
        padding-bottom: 30px;
    }

    .watch_trailer_extreme_right {
        padding-left: 4px;
    }

    .carousel_correction {
        margin-left: -15px;
        position: relative;
    }

    .download_pdf_center {
        margin-left: -16px;
    }

    .watch_trailer_left {
        margin-left: -2px;
        padding-left: 4px;
    }
    .watch_trailer_center {
        padding-left: 10px;
    }

    .download_pdf_right {
        margin-left: -7px;
    }
    .watch_trailer_right {
        padding-left: 0px;
        margin-left: -1px;
    }


    .btncontainer {
        width: 188px;
        height: 120px;
        display: block;
        margin-top: 5px;
        margin-right: 60px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .topbtn1 {
        background: url("https://wbtvd.com/opentemplate/5962/IMAGE/topbtn1.png") no-repeat scroll center center;
        display: block;
        width: 187px;
        height: 60px;
    }

    .topbtn2 {
        background: url("https://wbtvd.com/opentemplate/5962/IMAGE/topbtn2.png") no-repeat scroll center center;
        display: block;
        width: 188px;
        height: 59px;
    }

    /* VIDEO PLAYER */
    #opentempl_top {
        display: none;
        width: 100%;
        height: 100%;
        height: auto;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.9);
        z-index: 999;
    }

    .opentempl_video_player {
        background-color: rgba(0,0,0,1);
    }

    #opentempl_vsplayer {
        display: block;
        background-color: rgba(0,0,0,1);
        position: absolute;
        width: 760px;
        height: 430px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }

    #opentempl_top .btn-close {
        background: url("https://wbtvd.com/opentemplate/5961/IMAGE/overlay_close.png") no-repeat scroll center center rgba(0, 0, 0, 0);
        height: 39px;
        opacity: 0.5;
        position: absolute;
        right: 40px;
        top: 30px;
        width: 39px;
    }

    #opentempl_top .btn-close:hover {
        opacity: 1;
    }



    .col-md-3 {
        width: 25%;
    }

    h4 {
        text-align: center;
    }
}

