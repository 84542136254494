/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * alert-warning.less
 */
@import '../../styles/app/utils/utils.less';

.alert {
    h3, h4, h5 {
        margin-bottom: 0;
    }
    ul, .error-list {
        margin: 0;
        padding: 0;
        list-style: none;
        padding-left: 1.5em;
        li {
            margin-top: 0.6em;
            &:first-child {
                margin-top: 1em;
            }
        }
    }
}
