/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.op_container_harry_potter {
	ul {
		list-style: revert;
		padding: revert;
		margin: revert;
		color: #666;
	}

	a.thumbnail-title, a.thumbnail-title:hover {
	    font-weight: bold;
	    color: #8c8552;
	    font-size: 120%;
	}
	.thumbnail-container img.thumbnail-border{
		border: none;
	}
	.btn-gray{
		background-color: #1b1e22;
		color: #bcb791;
	}
	.btn-gray:hover{
		background-color: #bcb791;
		color: #1b1e22;
	}
	.hover{
		background-position: center;
		background-size: cover;
		display:block;
		border: 3px solid #8c8552;
	}
	.hover:hover img{
		visibility: hidden;
	}

	.hover-core-elements{
		background-image: url('./temp-images/ww-animated-core-elements.gif');
	}
	.hover-editorial-packages{
		background-image: url('./temp-images/ww-animated-editorial-packages.gif');
	}
	.hover-on-air-navigation{
		background-image: url('./temp-images/ww-animated-on-air-navigation.gif');
	}
	.hover-promo-packages{
		background-image: url(./temp-images/ww-animated-promo-packages.gif);
	}
	.header-background {
		background-image: url('./images/ww-header-bg.jpg');
	}
}
