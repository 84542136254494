@import "../../styles/bootstrap/variables.less";

.player-clipping-mode-on {
    .vjs-mouse-display, #thumbnails-sprite, .bmpui-ui-seekbar-label {
        display: none !important;
        opacity: 0 !important;
    }

    .bmpui-ui-settings-panel {
        z-index: 3;
    }
}

.bmpui-ui-uicontainer .slider-filler {
    top: 4px;
    bottom: 4px;
}

.clipping {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .slider-handle {
        background-color: @brand-yellow;
    }
}

.clipping-handle {
    position: absolute;
    min-width: 160px;
    bottom: 22px;
    cursor: pointer;

    .clipping-handle-line {
        background-color: @brand-yellow;
        height: 100%;
        margin-top: 15px;
        position: absolute;
        transform: translateY(6px);
        width: 4px;
    }

    .clipping-handle-content {
        position: relative;
        border: 2px solid @brand-yellow;
        background-color: black;
        width: 100%;
        overflow: hidden;
        min-height: 24px;

        .clipping-handle-content-thumbnail {
            width: 100%;
            transform: translateX(-2px);
        }

        .clipping-handle-content-tc {
            color: white;
            font-size: 16px;
            font-weight: bold;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.7);
            bottom: 0;
            right: 0;
            left: 0;
            text-align: center;
            padding: 3px;
        }
    }
}

.clipping-handle-left {
    .clipping-handle-line {
        right: 0;
        left: inherit;
    }
    .clipping-handle-content {
        transform: translateX(4px);
    }
}

.vjs-progress-control-disable-mouse-display .vjs-mouse-display {
    display: none !important;
}
