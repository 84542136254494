.op_container_tv_event {
	box-sizing: border-box;
	width: 978px !important;
	background: #fff;

	.pageWrapper{width:auto;}
	#top, .opentempl_htmlcontent #op_content {max-width:978px;margin: 0 auto;}

	.globalNav{display:none;}
	.header{height:79px;}


	#op_header {
		background: #F7F6FE url("https://www.wbtvd.com/page/tv-event/banner_bg_repeat.jpg") repeat-x scroll center center;
	}
	#op-header-img {
		background: transparent url("https://www.wbtvd.com/page/tv-event/banner_art.jpg") no-repeat scroll top center;
		background-size:contain;
		height:0;
		margin: 0 auto;
		max-width: 978px;
		padding-top:26.042%;
		position:relative;
	}
	#header-btns {
	position: absolute;
	top: 65%;
	right: 0;
	left: 47%;
	margin: 0 auto;
	max-width:450px;
	}

	a.btn-header {
		display: inline-block;
		background: transparent no-repeat scroll top center;
		background-size: contain;
		text-indent: -99999px;
		max-width:139px;
		width:30%;
		height:0;
		padding-top:12.518%;
		margin:0 0.75% 10px;
	}
	a.btn-header.presentation {
		background-image: url("https://www.wbtvd.com/page/tv-event/banner_btn_presentation.png");
	}
	a.btn-header.gfx {
		background-image: url("https://www.wbtvd.com/page/tv-event/banner_btn_gfx.png");
	}
	a.btn-header.reel {
		background-image: url("https://www.wbtvd.com/page/tv-event/banner_btn_sizzlereel.png");
	}
	a.btn-header.reel[href='#'] {
		display:none;
	}
	@media (min-width:960px){
		#op-header-img {
			height:250px;
			padding:0;
		}
		a.btn-header {
			height:58px;
			width:100%;
			padding:0;
			margin-bottom: 10px;
		}
	}


	.content_container {
		width: 25%;
		float:left;
		padding: 10px 0.25em 1em;
		margin: 0;
	}

	.content_container a.poster,
	.content_container a.poster:focus
	.content_container a.poster:hover {
		display:block;
		margin: 0 auto;
		height:0;
		padding-top:127.75%;
		width: 100%;
		max-width:227px;
		text-decoration:none;
		background: transparent no-repeat scroll center center;
		background-size: cover;
		border:1px solid #333;
		text-indent: -99999px;
	}
	.content_container a.poster {
		background-color:rgba(255,255,255,0.1);
	}
	.content_container img {
		width: 100%;
		height: auto;
		margin: 0 auto;
		max-width: 100%;
	}

	#op_footer {
		padding: 30px 15px;
		text-align:center;
		color: #aaa;
	}

	.footer ul {margin:32px 0 0;width:25%;}
	.footer ul > li {
	padding-left: 10px;
	}

	.btns {
		margin: 10px auto 5px;
		max-width: 227px;
		clear:both;
	}

	a.btn {
		display: block;
		background: transparent  no-repeat scroll top center;
		background-size: contain;
		text-indent: -99999px;
		border-radius: 2px;
		border: 1px solid #fff;
	}

	a.btn.disabled {
		opacity:0.5;
		cursor:default;
		pointer-events: none;
	}

	.btn.btn-gfx {
		background-image: url("https://www.wbtvd.com/page/tv-event/btn_gfx.png");
		width:44.75%;
		float:left;
	}
	.btn.btn-trailer {
		background-image: url("https://www.wbtvd.com/page/tv-event/btn_trailer.png");
		width:55.25%;
		clear:both;
		margin:auto;
	}
	a.btn span {
		display:block;
		width:100%;
		height:0;
	}
	a.btn.btn-info span,
	a.btn.btn-gfx span{
		padding-top:34.34%;
	}
	a.btn.btn-trailer span {
		padding-top:27.87%;
	}


	/* Medium Devices, Desktops */
	@media only screen and (max-width : 977px) {

		.pageWrapper, .footerWrapper {width:100%;}
		.globalNav {display:none;}
		.internal_links {clear:both;}
	}

	/* Small Devices, Tablets */
	@media only screen and (max-width : 767px) {
		.content_container {width: 33%;}
		.globalSearch {display:none;}
		.globalNav ul {width:auto;}
		.globalNav {display:none;}

		a.btn-header{margin-bottom:2.5%;}

		.header h1 {float:none;margin:0 auto;width:95%;max-width:418px;}
		.header h1 a {width:100%;background-size:contain;}
		.userLogdIn {display:none;}
	}

	/* Extra Small Devices, Phones */
	@media only screen and (max-width : 479px) {
		.content_container {
			width: 50%;
		}
		.content_container a {
		}

		#op-header-img {
			background-image: url("https://www.wbtvd.com/page/tv-event/banner_art_mobile.jpg");
			height:0;
			padding-top:34.4%;
		}

		#header-btns {
			left: 30%;
		}

		a.btn-header{margin-bottom:1%;}

		.header {width:90%;float:none;margin:0 auto;clear:both;display:table;height:auto;}
		.header h1 {width:100%;text-align:center;}
		.header h1 a {height:0;padding-top:10.28%;margin:0 auto;}
		.opentempl_htmlcontent > h2 {margin-top:0.5em;}


		.footer ul {margin:32px 0 0;width:50%;}
		.footer ul > li {padding-left:10px;}
		.footerWrapper {background-size:100% 110%;}
		.footer .global_links,.footer .internal_links {display:table;clear:both;}

	}

	/* Custom, iPhone Retina */
	@media only screen and (max-width : 320px) {
		.content_container {
			width: 50%;
		}
		.footerWrapper {
			display:none;
		}
	}

	.copyright p {width:100%;}



	/* VIDEO PLAYER */
	#opentempl_modal {
		display: none;
		width: 100%;
		height: 100%;
		height: auto;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0,0,0,.9);
		z-index: 999;
		color:#fff;
	}
	.opentempl_modal_player {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: auto;
		max-width: 766px;
		max-height: 570px;
		z-index:1;
	}

	#opentempl_modal_title {
		font-size: 2em;
	}

	.opentempl_playerContainer {

	}

	.opentempl_playerLoading {
		background-color:#333;
		text-align:center;
		margin:25% auto;
		width:56px;
		height:56px;
		padding:12px;
		border-radius:12px;
		box-sizing:border-box;
	}
	.opentempl_playerLoading img.spinner {
		width:32px;
		height:32px;
	}

	.opentempl_video_player {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
	}

	#opentempl_vsplayer {
		display: block;
		background-color: rgba(0,0,0,1);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: auto;
	}

	#opentempl_modal .btn-close {
		background: url("https://www.wbtvd.com/page/tv-event/overlay_close.png") no-repeat scroll center center rgba(0, 0, 0, 0);
		height: 39px;
		opacity: 0.5;
		position: absolute;
		right: 40px;
		top: 30px;
		width: 39px;
		z-index:2;
	}

	#opentempl_modal .btn-close:hover {
		opacity: 1;
	}

	.opentempl_modal_title {margin-bottom:0;font-size:2em;}
	#opentempl_modal_info {}

	@media(max-width:766px) {
		#opentempl_modal_title {
			font-size:1.5em;
		}
	}
}
