.op_container_animation_package {
    width: 100%;
    max-width:978px;

    #op_content {max-width:978px;margin: 0 auto;}


    /* .op_container, .op_container * {
        box-sizing: border-box;
    } */

    #op-header-img {
        background: transparent url("https://www.wbtvd.com/page/animation-package/banner_art.jpg") no-repeat scroll top center;
        background-size:contain;
        height:250px;
        margin: 0 auto;
        padding:0;
        max-width: 960px;
        position:relative;
    }
    #header-btns {
        position: absolute;
        top: 65%;
        right: 0;
        left: 30%;
        text-align: center;
        margin: 0 auto;
        max-width:450px;
    }

    a.btn-header {
        display: inline-block;
        background: transparent no-repeat scroll top center;
        background-size: contain;
        text-indent: -99999px;
        max-width:142px;
        height:60px;
        width:100%;
        padding:0;
        margin:0 0.75% 10px;
    }
    a.btn-header.catalog {
        background-image: url("https://www.wbtvd.com/page/animation-package/banner_btn_catalog.png");
    }
    a.btn-header.avails {
        background-image: url("https://www.wbtvd.com/page/animation-package/banner_btn_avails.png");
    }
    a.btn-header.reel[href='#'] {
        display:none;
    }

    .content_container {
        position: relative;
        padding: 10px 0.25em 1em;
        margin: 0;
    }

    .content_container a.poster,
    .content_container a.poster:focus
    .content_container a.poster:hover {
        display:block;
        position: relative;
        margin: 0 auto;
        height:0;
        padding-top:145%;
        width: 100%;
        text-decoration:none;
        background: transparent no-repeat scroll center center;
        /* background-size: cover; */
        background-size: 100% 100%;
        border: 1px solid #333;
        text-indent: -99999px;
    }
    .content_container a.poster {
        background-color:rgba(255,255,255,0.1);
    }
    .content_container img {
        width: 100%;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
    }

    #op_footer {
        padding: 30px 15px;
        text-align:center;
        color: #aaa;
    }

    .footer ul {margin:32px 0 0;width:25%;}
    .footer ul > li {
        padding-left: 10px;
    }

    .btns {
        margin: 10px auto 5px;
        max-width: 227px;
        clear:both;
    }

    a.btn {
        display: block;
        background: transparent  no-repeat scroll top center;
        background-size: contain;
        text-indent: -99999px;
        border-radius: 2px;
        border: 1px solid #fff;
    }

    a.btn.disabled,
    a.btn[href$="/videos/"] {
        opacity:0.5;
        cursor:default;
        pointer-events: none;
    }

    .btn.btn-gfx {
        background-image: url("https://www.wbtvd.com/page/animation-package/btn_gfx.png");
        width:44.75%;
        float:left;
    }
    .btn.btn-trailer {
        background-image: url("https://www.wbtvd.com/page/animation-package/btn_trailer.png");
        width:55.25%;
        clear:both;
        margin:auto;
    }
    a.btn span {
        display:block;
        width:100%;
        height:0;
    }
    a.btn.btn-info span,
    a.btn.btn-gfx span {
        padding-top:34.34%;
    }
    a.btn.btn-trailer span {
        padding-top:27.87%;
    }


    @media (max-width:959px) {
        #op-header-img {
            height:0;
            padding-top:26.042%;
        }
        a.btn-header {
            width:30%;
            margin-bottom: 10px;
            height:0;
            padding-top:12.518%;
        }
        .op_container/*, .op_container **/ {
            width: 100%;
        }
    }
    @media (max-width: 480px) {
        /* #header-btns {
            position: relative;
            top:0;
            left:0;
            right:0;
            bottom:0;

        } */
        .col-xxs-6 {
            width:50%;
        }
    }
    .copyright p {width:100%;}


}

