/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * Terms & Condition styling
 */


@import '../../styles/app/utils/utils.less';

.terms-of-use {
    overflow-y: auto;
    max-height: 400px;
    margin-bottom: @offset * 1.5;
    h1, h2, h3, h4, h5, h6 {
        font-size: 1.2em;
        margin-top: 0.8em;
        margin-bottom: 0.4em;
        text-transform: uppercase;
    }
    p {
        margin-bottom: 0.8em;
    }
}
