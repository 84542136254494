/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.label {
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    vertical-align: baseline;
    border-radius: .25em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 3px;
    padding: 3px 6px;
    border:1px solid #0068b3;
    color: #0077CC;
}

.container {
    font-size: 16px;
    margin-bottom: 2px;
}
