.sticky-footer-container {
    .footer-nav {
        background: #0022af;
        min-height: 60px;
        padding-bottom: 0px;
    
        hr {
            border-color:#999;
        }
    
        a {
            color:#AAA;
        }
    
        .footer-toggle {
            cursor:pointer;
            color: #FFF;
            font-size:18px;
        }
    
        .footer-list {
            height:0;
            display:none;

            &.active {
                display:block;
                height:auto;
                padding-bottom:20px;
            }

            .footer-links {
                a {
                    color: #FFF;
                }
                .caption-description-alone {
                    &:hover {
                        color: #FFF;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .footer-copyright {
        background:#04006c;
        color:#FFF;
    
        .app-version {
            color: #04006c;
        }
    }
}
