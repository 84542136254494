/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.box {
    padding-left: 5px;
    padding-right: 5px;
}

.merchitem {
    background: #F7F7F7;
    padding: 20px;
    margin-bottom: 10px;
}

.title, .price {
    font-weight: bold;
}

.title {
    margin: 10px 0;
}

.titleBold {
    font-size: 18px !important;
}

.price {
    margin: 0 0 10px;
}
