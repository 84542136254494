/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

.help-header {
    background: #111111;
    color: #ffffff;
    overflow: hidden;

    .container {
        box-shadow: 0 0 30px #000000;
        position: relative;
        padding: 0;
    }
}

.help-content {
    .video-box {
        .glyphicon-play-circle {
            color: #ffffff;
            font-size: 60px;
            margin-left: -30px;
            margin-top: -30px;
            position: absolute;
            opacity: 0.5;
            top: 50%;
            left: 50%;
        }
    }
}
