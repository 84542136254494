/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/**
 * Video List
 */
.thumbnail-container p strong div, .homepage-video-description h1 strong div {
    display:inline;
}

.thumbnail-link.video-thumbnail:focus {
    outline-offset: 2px;
}

#video-player {
    width: 100% !important;
    position: relative !important;
    overflow: hidden !important;
}
